import { NetworkBottomIcons, calcTextLength, capitalNames, convertToRgbColor, useEffect, useState, Skeleton } from "./Imports_Network_MiniCardThumbnail";

const Network_MiniCardThumbnail = ({ setOpenCardPopup, data, tint, baseColor }) => {
    const { location, name, logo } = data
    const newAddress = `${location?.city && location?.state && location.city === location.state ? "" : location?.city ? location.city + "," : ""} ${location?.state ? location.state + "," : ""} ${location?.country}`;

    const [isLoading, setIsLoading] = useState(true);
    let color = convertToRgbColor(baseColor);
    const rgba = `rgba(${color?.[0]},${color?.[1]},${color?.[2]},50%)`;

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setIsLoading(false);
        };
        img.src = data?.cardInfo?.[0]?.cardShortBgURL;
    }, [data?.cardInfo?.[0]?.cardShortBgURL]); //eslint-disable-line

    return (
        <>
        {
            isLoading ? <Skeleton height={85} width={57} baseColor={rgba} className="network_minicard_thumbnail_shimmer" /> :
                <div className="network_minicard_thumbnail" onClick={() => setOpenCardPopup(true)}
                    style={{ backgroundImage: tint ? `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${data?.cardInfo?.[0]?.cardShortBgURL})` : `url(${data?.cardInfo?.[0]?.cardShortBgURL})`,
                        backgroundColor: "#000" }}>
                    <img src={logo} className='network_image' alt="" />
                    <div className="network_name">{name}</div>
                    <div className="network_location">{capitalNames(newAddress)}</div>
                    <NetworkBottomIcons />
                </div>
            }
        </>
    )
}

export default Network_MiniCardThumbnail
