const CountryCodeSearch = ({ Spinner, searchIconLoader, searchIcon, setSearchIconLoader, 
    searchVal, handleDropdownSearch, setSearchVal }) => {
        
    return (
        <div className="searchable-country-codes-search-container">
            <div className="searchable-country-codes-search-icon-container">
                <Spinner variant="danger" className={searchIconLoader ? "searchable-country-codes-search-icon-loader" : "d-none"} />
                <img src={searchIcon} alt="" className={searchIconLoader ? "d-none" : "searchable-country-codes-search-icon"}
                    onLoad={() => setSearchIconLoader(false)} />
            </div>
            <input type="text" value={searchVal} onChange={(e) => handleDropdownSearch(e, setSearchVal)} 
                placeholder="Search by country code / name" className="searchable-country-codes-search-input" />
        </div>
  )
}

export default CountryCodeSearch;