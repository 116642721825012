import { useState } from "react";
import Skeleton from "react-loading-skeleton"

const NetworkIconBadgeSingle = ({ item, baseColor }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="network-icon-badge">
    <Skeleton
      circle
      height={31}
      width={31}
      baseColor={baseColor}
      className={
        isLoading ? "d-block network-icon-badge-shimmer" : "d-none"
      }
    />
    <img
      src={item?.logo}
      alt=""
      className={isLoading ? "d-none" : "d-block"}
      onLoad={() => setIsLoading(false)}
    />
  </div>
  )
}

export default NetworkIconBadgeSingle