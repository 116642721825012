import React from 'react'
import './netWorksListShimmer.scss'
import Skeleton from 'react-loading-skeleton'


export const NetworkListShimmer = () => {
  return (
    <div className='networkList_shimmer'>
      <InnerShimmer />
      <InnerShimmer />
      <InnerShimmer />
      <InnerShimmer />
      <InnerShimmer />
      <InnerShimmer />
      <InnerShimmer />
      <InnerShimmer />
      <InnerShimmer />
      <InnerShimmer />
    </div>
  )
}

const InnerShimmer = () => {
    return(
        <div className="innerNetworkListShimmer">
            <Skeleton width={48} height={48} circle baseColor={'#CFD4DF'} style={{marginRight:"12px"}}/>
            <div className="linner">
                <Skeleton width={251} height={10}  baseColor={'#CFD4DF'} borderRadius={13}/>
                
                <Skeleton width={145} height={10} borderRadius={13} baseColor={'#CFD4DF'}/>
            </div>

        </div>
    )
}


