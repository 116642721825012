import { useState , capitalNames, handleShareProfile, Spinner, avatar, Skeleton, shareIcon } from "./ImportsNetworkCardDataProfile";

const NetworkCardDataProfile = ({ dpURL, networkName, newAddress, data }) => {
  const [shareLoader, setShareLoader] = useState(true);
  const [profileImageLoader, setprofileImageLoader] = useState(true);
  const baseColor = "#CFD4DF";

  return (
    <>
      <div className="network-top-sharing">
        <div
          className="network-share-icon"
          onClick={() => handleShareProfile(data)}
        >
          <Spinner
            animation="border"
            variant="light"
            size="sm"
            className={shareLoader ? "d-block" : "d-none"}
          />
          <img
            src={shareIcon}
            alt=""
            className={shareLoader ? "d-none" : "d-block"}
            onLoad={() => setShareLoader(false)}
          />
        </div>
        <span
          className="network-share-text"
          onClick={() => handleShareProfile(data)}
        >
          Share
        </span>
      </div>
      <div className="network-profile-image">
        <Skeleton
          circle
          height={127}
          width={127}
          baseColor={baseColor}
          className={profileImageLoader ? "d-block" : "d-none"}
        />
        <img
          src={dpURL === "" ? avatar : dpURL}
          alt=""
          className={profileImageLoader ? "d-none" : "d-block"}
          onLoad={() => setprofileImageLoader(false)}
        />
      </div>
      <div className="network-name-div">
        <div className="network-title-name">
          {networkName}
        </div>
      </div>
      <div className="network-address-container">
        <div className="network-address">
          {capitalNames(newAddress)}
        </div>
      </div>
    </>
  );
};
export default NetworkCardDataProfile;
